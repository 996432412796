import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Button, Divider, Grid, RadioGroup, styled, Switch, Tab, useTheme} from "@mui/material";
import AppModal from "components/AppModal";
import React, {ChangeEvent, FC, SyntheticEvent, useEffect, useState} from "react";
import AppAvatar from "../../../components/avatars/AppAvatar";
import AppTextField from "../../../components/input-fields/AppTextField";
import {H5, Tiny} from "../../../components/Typography";
import AppRadio from "../../../components/AppRadio";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageUpload from "../../../page-sections/admin-ecommerce/add-product/ImageUpload";
import FlexBox from "../../../components/flexbox/FlexBox";
import HttpService from "../../../utils/axios";
import {useNavigate} from "react-router-dom";

// custom styled components
const StyledTab = styled(Tab)(({theme}) => ({
    fontSize: 13,
    minHeight: "auto",
    color: theme.palette.text.primary,
}));

const StyledAppModal = styled(AppModal)(({theme}) => ({
    padding: 30,
    maxWidth: 800,
    minWidth: 800,
}));

// ------------------------------------------------------------------
type FragmentsModalProps = {
    openModal: boolean;
    closeModal: () => void;
    fragment: any;
};

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    "& .MuiTypography-root": {fontSize: 12, fontWeight: 600},
}));
// ------------------------------------------------------------------

const FragmentsModal: FC<FragmentsModalProps> = ({
                                                     openModal, closeModal, fragment
                                                 }) => {
    const navigate = useNavigate();
    const [stockLocation, setStockLocation] = useState("");
    const [amount, setAmount] = useState(0);
    const [sku, setSku] = useState('');
    const [name, setName] = useState('');
    const [grams, setGrams] = useState(0.00);
    const [price, setPrice] = useState(10.00);
    const [files, setFiles] = useState([]);
    const [directStore, setDirectStore] = useState(false);


    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    };

    useEffect(() => {
        if (fragment !== undefined) {
            setName(fragment.name);
            setGrams(fragment.grams);
            setPrice(fragment.price);
            setSku(fragment.sku);
        }
    }, [fragment])

    const handleClose = () => {
        closeModal();
    }

    const handleDropFile = (acceptedFiles: any) => {
        const oldFiles = files;
        const newFiles = acceptedFiles.map((file: any) =>
            Object.assign(file, {preview: URL.createObjectURL(file)})
        );
        // @ts-ignore
        setFiles([...oldFiles, ...newFiles]);
    };

    const handleRemoveImage = (file: File | string) => {
        const filteredFiles = files.filter((_file) => _file !== file);
        setFiles(filteredFiles);
    };

    const isNullOrEmpty = (str: string | undefined) => {
        return str === undefined || str === ''
    };

    const checkIfReady = () => {
        let ready = true;
        if (isNullOrEmpty(name) || isNullOrEmpty(sku))
            ready = false;
        if (files.length === 0 && !fragment)
            ready = false;
        if (directStore && isNullOrEmpty(stockLocation))
            ready = false;
        return ready;
    }

    const createFragment = () => {
        let formData = new FormData();
        // @ts-ignore
        formData.append('name', name);
        // @ts-ignore
        formData.append('sku', sku);
        // @ts-ignore
        if (files?.[0])
            formData.append('image', files[0]);
        // @ts-ignore
        formData.append('grams', grams);
        // @ts-ignore
        formData.append('price', price);
        if (directStore) {
            // @ts-ignore
            formData.append('stockLocation', stockLocation);
            // @ts-ignore
            formData.append('amount', amount);
        }

        return HttpService.getAxiosClient()
            .post("https://api.tomorrowbrand.de/v1/products/fragments/" + (fragment ? fragment.id : ''), formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            }).finally(() => {
                window.location.reload();
            });
    }

    return (
        <StyledAppModal open={openModal} handleClose={handleClose}>
            <TabContext value='1'>

                <TabPanel value="1">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <H5>Ein Fragment {fragment !== undefined ? 'bearbeiten' : 'hinzufügen'}</H5>
                        </Grid>
                        <Grid item xs={4}>
                            <AppTextField label="Name" fullWidth required value={name}
                                          onChange={(e) => setName(e.target.value)}/>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <AppTextField label="Artikelnummer" fullWidth required value={sku}
                                          onChange={(e) => setSku(e.target.value)}/>
                        </Grid>
                        <Grid item sm={3} xs={6}>
                            <AppTextField label="Gewicht (gramm)" fullWidth required value={grams}
                                // @ts-ignore
                                          onBlur={() => setGrams(Number(grams.replace(',', '.')).toFixed(2))}
                                // @ts-ignore
                                          onChange={(e) => setGrams(e.target.value)}/>
                        </Grid>
                        <Grid item sm={2} xs={6}>
                            <AppTextField label="Preis" fullWidth required value={price}
                                // @ts-ignore
                                          onBlur={() => setPrice(Number(price.replace(',', '.')).toFixed(2))}
                                // @ts-ignore
                                          onChange={(e) => setPrice(e.target.value)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUpload
                                handleRemoveImage={handleRemoveImage}
                                onDrop={handleDropFile}
                                files={files}
                            />
                        </Grid>
                        {!fragment && <Grid item xs={12}>
                            <Tiny>Fragment direkt einlagern?</Tiny>
                            <span>Nein</span>
                            <Switch onChange={(e) => setDirectStore(!directStore)}/>
                            <span>Ja</span>
                        </Grid>}
                        {directStore && <>
                            <Grid item xs={6}>
                                <AppTextField label="Location Code" fullWidth required value={stockLocation}
                                              onChange={(e) => setStockLocation(e.target.value)}/>
                            </Grid>
                            <Grid item xs={6}>
                                <AppTextField label="Menge" fullWidth required value={amount} type='number'
                                    // @ts-ignore
                                              onBlur={() => setAmount(Number(amount).toFixed(0))}
                                    // @ts-ignore
                                              onChange={(e) => setAmount(e.target.value)}/>
                            </Grid>
                        </>}
                        <Grid item xs={12}>
                            <FlexBox flexWrap="wrap" gap={2}>
                                <Button variant="contained" disabled={!checkIfReady()} onClick={() => createFragment()}>Fragment speichern</Button>
                            </FlexBox>
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </StyledAppModal>
    );
};

export default FragmentsModal;
